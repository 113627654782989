<template>
  <capillaries-template
    :prominent-blood-vessels="prominentBloodVessels"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import CapillariesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/capillaries/CapillariesTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const fieldsToReset = ['bloodVesselLocations', 'firstBloodVesselOccurrance'];

const FIELDS = [...fieldsToReset.map(field), requiredField('prominentBloodVessels')];

export default {
  name: 'Capillaries',
  components: {
    CapillariesTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    prominentBloodVessels(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
